import hotkeys from "hotkeys-js";
import { useCallback, useEffect } from "react";
export function useHotkeys(keys, callback, deps = [], options = {}) {
    const memoisedCallback = useCallback(callback, deps);
    useEffect(() => {
        if (options.filter)
            hotkeys.filter = options.filter;
        hotkeys(keys, memoisedCallback);
        return () => hotkeys.unbind(keys, memoisedCallback);
    }, [memoisedCallback, options]);
}
